.experience-accord {
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left:60px;
  margin-right:60px ;
}

.accord-panel {
  font-family: "Google Sans Regular";
}

@media (max-width: 768px) {
  .experience-accord {
    margin: 20px;
  }
}
