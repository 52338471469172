/*Blog Cards Layout*/

.square {
  width: 440px;
  height: 430px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 20px 50px #8a8a8a;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-top: 30px;
}

.square:hover {
  box-shadow: 0 20px 40px #000000;
}

.blog-header-text {
  text-align: center;
  font-size: 56px;
  font-weight: 400;
  font-family: "Google Sans Medium";
  margin-top: 20px;
}

.blog-subtitle {
  text-transform: uppercase;
  
}
.blog-main-div > * {
  flex: 1;
  margin-bottom: -10px;
  margin-top: 30px;
}
.blog-main-div {
  display: flex;
}
.blog-image-div > img {
  max-width: 100%;
  height: auto;
}
.blog-text-div {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, fr));
  gap: 32px;
}
.blog-subTitle {
  text-align: center;
  margin-top: 25px;
}

/* Media Query */
@media (max-width: 1380px) {
  .blog-header-text {
    font-size: 40px;
  }
  .blog-subTitle {
    margin-bottom: 30px;
  }
  .blog-main-div {
    flex: 1;
    margin-top: 0px;
  }
}
@media (max-width: 768px) {
  .blog-header-text {
    font-size: 30px;
    text-align: center;
    margin-top: -40px;
  }
  .blog-subTitle {
    font-size: 16px;
    line-height: normal;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
}

@media screen and (max-width: 480px) and (min-width: 300px) {
  .blog-text-div {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}
