.topButton {
  display:-moz-inline-box;
  visibility: hidden;
  position: fixed;
  bottom: 1.2rem;
  right: 1.5rem;
  cursor: pointer;
  padding: 15px;
  height: 50px;
  width: 50px;
  justify-content: center;
  text-align: center;
  line-height: 1.1;
  outline: none;
  border-radius: 40px;
  font-size: 1.2rem;
}
