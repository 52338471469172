@font-face {
  font-family: "Agustina Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Agustina Regular"),
    url("./assets/fonts/Agustina.woff") format("woff");
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./assets/fonts/Montserrat-Regular.ttf") format("woff");
}
@font-face {
  font-family: "Google Sans Bold";
  src: local("Google Sans Bold"),
    url("./assets/fonts/GoogleSans-Bold.ttf") format("woff");
}
@font-face {
  font-family: "Google Sans Bold Italic";
  src: local("Google Sans Bold Italic"),
    url("./assets/fonts/GoogleSans-BoldItalic.ttf") format("woff");
}
@font-face {
  font-family: "Google Sans Italic";
  src: local("Google Sans Italic"),
    url("./assets/fonts/GoogleSans-Italic.ttf") format("woff");
}
@font-face {
  font-family: "Google Sans Medium";
  src: local("Google Sans Medium"),
    url("./assets/fonts/GoogleSans-Medium.ttf") format("woff");
}
@font-face {
  font-family: "Google Sans Medium Italic";
  src: local("Google Sans Medium Italic"),
    url("./assets/fonts/GoogleSans-MediumItalic.ttf") format("woff");
}
@font-face {
  font-family: "Google Sans Regular";
  src: local("Google Sans Regular"),
    url("./assets/fonts/GoogleSans-Regular.ttf") format("woff");
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.main-page {
  min-width: 100vw;
  min-height: 100vh;
}
html,
body {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
body {
  margin: 0;
  font: 19px / 23px Montserrat, "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Media Query */
@media (max-width: 1380px) {
  html,
  body {
    font-size: 16px;
    line-height: normal;
  }
}

/* Classes from bootstrap */
/* Credit: BootStrap */

.btn.disabled {
  pointer-events: none;
  opacity: 0.65;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  font-family: "Google Sans Regular";
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #6c757d;
}
.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border-radius: 0.3rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.container {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.col-md-12 {
  flex: 0 0 auto;
  width: 100%;
}

.alert {
  display: none;
  margin-top: 1rem;
  position: relative;
  padding: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
  margin-top: 0;
  margin-bottom: 0 rem;
  font-weight: 500;
  line-height: 1.2;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.alert-error {
  color: #e7f84d;
  background-color: #ec2112;
  border-color: #badbcc;
}

.fade {
  transition: opacity 0.15s linear;
}

.justify-content-center {
  justify-content: center !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.show {
  display: inline-block;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}
