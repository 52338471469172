.hobbies-main {
  width: 100vw;
}

.hobbies-card {
  margin-top: 50px;
  text-align: center;
  margin-left: 5%;
  margin-right: 5%;
}
.hobbies-card-title {
  font-size: 35px;
  margin-top: 20px;
}

.button-hobbies-card-div {
  display: flex;
  margin-top: 20px;
  align-items:center;
}
.hobbies-card-subtitle {
  margin-top: 12px;
  font-size: 20px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .hobbies-image {
    width: 60%;
  }
  .hobbies-data {
    display: flex;
    flex-direction: row-reversE;
    overflow: hidden;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: nowrap;
  }
  .hobbies-data > * {
    position: relative;
    flex: 1;
    margin-bottom: 30px;
    /*margin-left: 30px;*/
  }

  .hobbies-image > * {
    max-width: 60%;
    height: auto;
    margin-top: 100px;
  }
}
@media (max-width: 768px) {
  .hobbies-card-title {
    font-size: 27px;
  }
  .hobbies-card-subtitle {
    font-size: 18px;
  }
  .hobbies-image {
    width: 80%;
  }
}
.checkthisout-btn-div {
  width: 100%;
  display: flex;
  justify-content: center;
}
