.contactMessage-main {
  margin-top: 5%;
  margin-bottom: 5%;
  font-size: 20px;
  font-family: "Google Sans Regular";
}
.cont-image {
  display: none;
  margin-top: 50px;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 30px;
}

.mb-1 {
  margin-bottom: 1rem;
}
.mb-3 {
  margin-bottom: 1rem;
}

.btn {
  background-color: #5d2a42;
  color: white;
  padding: 10px 14px;
  border: 0;
  outline: 0;
  border-radius: 5px;
  font-size: 16px;
  font-family: "Google Sans Regular";
}

.btn:hover {
  background-color: #fb6376;
  cursor: pointer;
}

@media (min-width: 768px) {
  .contactMessage-main {
    display: flex;
    flex-direction: row-reverse;
    text-align: center;
    align-items: center;
  }
  .cont-image {
    margin-top: 15%;
  }
}

@media (max-width: 768px) {
  #contact {
    text-align: center;
    align-items: center;
  }
}
